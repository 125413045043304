const strings = {
  /***** Alerts *****/

  'error--MicInit--NotAllowed': {
    en:
      'Automatic tuning needs permission to use your device\'s microphone. Please allow access to the microphone in the settings.',
    es:
      'La sintonización automática necesita permiso para usar el micrófono de su dispositivo. Permita el acceso al micrófono en la configuración.',
    fr:
      'Le réglage automatique nécessite une autorisation pour utiliser le microphone de votre appareil. Veuillez autoriser l\'accès au microphone dans les paramètres',
    zh: '自动调谐需要获得使用设备麦克风的许可。 请在设置中允许访问麦克风”',
    de:
      'Die automatische Sendersuche erfordert die Berechtigung, das Mikrofon Ihres Geräts zu verwenden. Bitte erlauben Sie den Zugriff auf das Mikrofon in den Einstellungen.',
  },
  'error--MicInit--Unknown': {
    en:
      'Please use Safari 13+ or desktop Chrome, Firefox or Safari for tuning with microphone.',
    es:
      'Utilice Safari 13+ o Chrome, Firefox o Safari en el escritorio para afinar con el micrófono.',
    fr:
      'Veuillez utiliser Safari 13+ ou desktop Chrome, Firefox ou Safari pour le accorder avec microphone',
    zh: '请使用Safari 13+或桌面Chrome、Firefox或Safari进行麦克风调谐。',
    de:
      'Bitte verwenden Sie Safari 13+ oder Desktop Chrome, Firefox oder Safari zum Abstimmen mit dem Mikrofon.',
  },

  unsupportedBrowser: {
    en:
      'Please use Safari 13+ or desktop Chrome, Firefox or Safari for tuning with microphone.',
    es:
      'Utilice Safari 13+ o Chrome, Firefox o Safari en el escritorio para afinar con el micrófono.',
    fr:
      'Veuillez utiliser Safari 13+ ou desktop Chrome, Firefox ou Safari pour le accorder avec microphone',
    zh: '请使用Safari 13+或桌面Chrome、Firefox或Safari进行麦克风调谐。',
    de:
      'Bitte verwenden Sie Safari 13+ oder Desktop Chrome, Firefox oder Safari zum Abstimmen mit dem Mikrofon.',
  },

  /***** SEO *****/

  title: {
    en: 'Guitar Tuner Online with Microphone | Guitar-Tuner.org',
    es: 'Afinador de Guitarra con Micrófono Online | Guitar-Tuner.org',
    fr: 'Accordeur de Guitare avec le Micro en ligne | Guitar-Tuner.org',
    zh: '吉他调谐器 | 在线调谐 | Guitar-Tuner.org',
    de: 'Gitarren Stimmgerät mit Mikrofon | Online Stimmen | Guitar-Tuner.org',
  },

  description: {
    en:
      'Tune your ukulele, acoustic, electric or bass guitar with microphone or by ear. Free online tuner for any guitar or ukulele.',
    es:
      'Afina tu ukelele, guitarra acústica, eléctrica o baja con un micrófono o de oído. El afinador online gratis para cualquiera guitarra o ukelele.',
    fr:
      "Accordez votre ukulélé, votre guitare acoustique, électrique ou basse avec un microphone ou à l'oreille. Accordeur en ligne gratuit pour la guitare ou ukulélé.",
    zh:
      '用麦克风或耳朵调好你的尤克里里、木吉他、电吉他或贝斯。免费在线调谐器适用于任何吉他或尤克里里。',
    de:
      'Stimmen Sie Ihre Ukulele, akustik, elektrische oder Bassgitarre mit dem Mikrofon oder nach Gehör. Kostenloses Online-Stimmgerät für die Gitarre oder Ukulele.',
  },

  'title-acoustic-guitar': {
    en: 'Acoustic Guitar Tuner with Microphone Online | Guitar-Tuner.org',
    es: 'Afinador de Guitarra Acústica con Micrófono Online | Guitar-Tuner.org',
    fr: 'Accordeur De Guitare Acoustique avec le Micro | Guitar-Tuner.org',
    zh: '木吉他调谐器 | 在线木吉他调音',
    de: 'Stimmgerät Akustik-Gitarre mit Mikrofon Online | Guitar-Tuner.org',
  },

  'description-acoustic-guitar': {
    en:
      'Easily tune your acoustic guitar with microphone or by ear. This precise acoustic guitar tuner will make your guitar sound perfect.',
    es:
      'Afina fácilmente tu guitarra acústica con un micrófono o de oído. Este afinador para guitarra acústica hará tu guitarra sonar perfectamente.',
    fr:
      "Accordez votre guitare acoustique avec un microphone ou à l'oreille. Cet accordeur de guitare précis vous aidera d'obtenir un son parfait.",
    zh:
      '用麦克风或耳朵轻松地调好你的木吉他。这个木吉他调谐器将使你的吉他听起来完美。简单而精确的音响调谐器。',
    de:
      'Stimmen Sie Ihre Akustik-Gitarre mit einem Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihre Gitarre perfekt klingen lassen.',
  },

  'title-electric-guitar': {
    en: 'Electric Guitar Tuner with Microphone Online | Guitar-Tuner.org',
    es: 'Afinador de Guitarra Eléctrica con Micrófono Online | Guitar-Tuner.org',
    fr: 'Accordeur De Guitare Électrique avec le Micro | Guitar-Tuner.org',
    zh: '电吉他调谐器 | 在线电吉他调音 | Guitar-Tuner.org',
    de: 'Stimmgerät E-Gitarre mit Mikrofon Online | Guitar-Tuner.org',
  },

  'description-electric-guitar': {
    en:
      'Easily tune your electric guitar with microphone or by ear. This precise eletric guitar tuner will make your guitar sound perfect.',
    es:
      'Afina tu guitarra eléctrica con un micrófono o de oído. Este afinador para guitarra eléctrica hará tu guitarra sonar perfectamente.',
    fr:
      "Accordez votre guitare électrique avec un microphone ou à l'oreille. Cet accordeur de guitare précis vous aidera d'obtenir un son parfait.",
    zh:
      '用麦克风或耳朵轻松地调好你的电吉他。激励人心的电吉他调谐器。电吉他调音从未如此容易！',
    de:
      'Stimmen Sie Ihre E-Gitarre mit einem Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihre Gitarre perfekt klingen lassen.',
  },

  'title-bass-guitar': {
    en: 'Bass Tuner with Microphone Online | Guitar Tuning | Guitar-Tuner.org',
    es: 'Afinador de Bajo con Micrófono Online | Guitar-Tuner.org',
    fr: 'Accordeur De Guitare Basse avec le Micro | Guitar-Tuner.org',
    zh: '贝斯调谐器 | 在线贝斯吉他调音 | Guitar-Tuner.org',
    de: 'Bass Stimmgerät mit Mikrofon Online | Guitar-Tuner.org',
  },

  'description-bass-guitar': {
    en:
      'Easily tune your bass guitar with microphone or by ear. With this online tuner you will make your bass sound great. Simple and precise bass tuner.',
    es:
      'Afina fácilmente tu guitarra baja con un micrófono o de oído. Este afinador online hará tu bass sonar bien. Simple y preciso afinador bajo.',
    fr:
      "Accordez votre guitare basse en ligne avec un microphone ou à l'oreille . Grâce à cet accordeur précis, vous obtiendrez un excellent son de basse.",
    zh:
      '用麦克风或耳朵轻松地调好你的贝斯吉他。这个在线调谐器将使你的贝斯听起来完美。简单而精确的贝斯调谐器。',
    de:
      'Stimmen Sie Ihre Bass-Gitarre Online mit Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihren Bass großartig klingen lassen.',
  },

  'title-ukulele': {
    en: 'Ukulele Tuner with Microphone Online | Guitar-Tuner.org',
    es: 'Afinador de Ukelele con Micrófono Online | Guitar-Tuner.org',
    fr: 'Accordeur d\'Ukulélé avec le Micro en ligne | Guitar-Tuner.org',
    zh: '尤克里里调谐器 | 在线尤克里里调音 | Guitar-Tuner.org',
    de: 'Ukulele Stimmgerät mit Mikrofon Online | Guitar-Tuner.org',
  },

  'description-ukulele': {
    en:
      'Tune your ukulele online with microphone or by ear. Simple and precise tuner makes everyday uke tuning easy.',
    es:
      'Afina tu ukelele con un micrófono o de oído. Afinar tu ukelele nunca ha sido tan facil. Simple y preciso afinador  por guitar-tuner.org.',
    fr:
      "Accordez votre ukulélé avec un microphone ou à l'oreille. Un accordeur simple et précis fait l'accord d'ukulélé très facile.",
    zh:
      '用麦克风或耳朵轻松地调好你的尤克里里。 在线尤克里里调音从未如此容易！简单而精确的尤克里里调谐器 guitar-tuner.org.',
    de:
      'Stimmen Sie Ihre Ukulele mit dem Mikrofon oder nach Gehör. Dieses präzises Stimmgerät wird Ihre Ukulele großartig klingen lassen.',
  },

  /***** Instruments *****/

  acoustic: {
    en: 'Acoustic',
    es: 'Acústica',
    fr: 'Acoustique',
    zh: '木',
    de: 'Akustik',
  },
  acousticJSX: {
    en: (
      <span>
        Acoustic<span className='xl-only'>&nbsp;Guitar</span>
      </span>
    ),
    es: (
      <span>
        <span className='xl-only'>Guitarra&nbsp;</span>Acústica
      </span>
    ),
    fr: (
      <span>
        <span className='xl-only'>Guitare&nbsp;</span>Acoustique
      </span>
    ),
    zh: <span>木吉他</span>,
    de: (
      <span>
        Akustik<span className='xl-only'>&nbsp;Gitarre</span>
      </span>
    ),
  },
  tune_acoustic: {
    en: 'Tune Acoustic',
    es: 'Afinar Acústica',
    fr: 'Accorder Acoustic',
    zh: '调木吉他',
    de: 'Akustik Stimmen',
  },
  bass: {
    en: 'Bass',
    es: 'Bajo',
    fr: 'Basse',
    zh: '贝斯',
    de: 'Bass',
  },
  bassJSX: {
    en: (
      <span>
        Bass<span className='xl-only'>&nbsp;Guitar</span>
      </span>
    ),
    es: <span>Bajo</span>,
    fr: (
      <span>
        <span className='xl-only'>Guitare&nbsp;</span>Basse
      </span>
    ),
    zh: <span>贝斯吉他</span>,
    de: (
      <span>
        Bass<span className='xl-only'>&nbsp;Gitarre</span>
      </span>
    ),
  },
  tune_bass: {
    en: 'Tune Bass',
    es: 'Afinar Bajo',
    fr: 'Accorder Basse',
    zh: '调贝斯',
    de: 'Bass Stimmen',
  },
  uke: {
    en: 'uke',
    es: 'uke',
    fr: 'uke',
    zh: '尤克',
    de: 'uke',
  },
  ukulele: {
    en: 'Ukulele',
    es: 'Ukelele',
    fr: 'Ukulélé',
    zh: '尤克里里',
    de: 'Ukulele',
  },
  ukuleleJSX: {
    en: <span>Ukulele</span>,
    es: <span>Ukelele</span>,
    fr: <span>Ukulélé</span>,
    zh: <span>尤克里里</span>,
    de: <span>Ukulele</span>,
  },
  tune_ukulele: {
    en: 'Tune Ukulele',
    es: 'Afinar Ukelele',
    fr: 'Accorder Ukulélé',
    zh: '调尤克里里',
    de: 'Ukulele Stimmen',
  },
  electric: {
    en: 'Electric',
    es: 'Eléctrica',
    fr: 'Électrique',
    zh: '电',
    de: 'Elektrische',
  },
  electricJSX: {
    en: (
      <span>
        Electric<span className='xl-only'>&nbsp;Guitar</span>
      </span>
    ),
    es: (
      <span>
        <span className='xl-only'>Guitarra&nbsp;</span>Eléctrica
      </span>
    ),
    fr: (
      <span>
        <span className='xl-only'>Guitare&nbsp;</span>Électrique
      </span>
    ),
    zh: <span>电吉他</span>,
    de: (
      <span>
        Elektrische<span className='xl-only'>&nbsp;Gitarre</span>
      </span>
    ),
  },
  tune_electric: {
    en: 'Tune Electric',
    es: 'Afinar Eléctrica',
    fr: 'Accorder Électrique',
    zh: '调电吉他',
    de: 'Elektrische Stimmen',
  },

  /***** UI *****/

  onlineGuitarTuner: {
    en: 'Online Guitar Tuner',
    es: 'Afinador de Guitarra Online',
    fr: 'Accordeur de Guitare en Ligne',
    zh: '在线吉他调音器',
    de: 'Stimmgerät Gitarre Online',
  },
  onlineAcousticTuner: {
    en: 'Acoustic Guitar Online Tuner',
    es: 'Afinador de Guitarra Acústica Online',
    fr: 'Accordeur de Guitare Acoustique en Ligne',
    zh: '在线原声吉他调音器',
    de: 'Stimmgerät Akustik-Gitarre Online',
  },
  onlineElectricTuner: {
    en: 'Electric Guitar Online Tuner',
    es: 'Afinador de Guitarra Eléctrica Online',
    fr: 'Accordeur de Guitare Électrique en Ligne',
    zh: '在线电吉他调音器',
    de: 'Stimmgerät Elektrische Gitarre Online',
  },
  onlineBassTuner: {
    en: 'Bass Guitar Online Tuner',
    es: 'Afinador de Bajo Online',
    fr: 'Accordeur de Guitare Basse en Ligne',
    zh: '低音吉他调音器在线',
    de: 'Bass Stimmgerät Online',
  },
  onlineUkuleleTuner: {
    en: 'Ukulele Online Tuner',
    es: 'Afinador de Ukelele Online',
    fr: 'Accordeur d\'Ukulélé en Ligne',
    zh: '尤克里里调音器在线',
    de: 'Ukulele Stimmgerät Online',
  },
  tuneYourInstruments: {
    en: 'Tune your acoustic, electric, bass guitar or ukulele',
    es: 'Afina tu ukelele, guitarra acústica, eléctrica o baja',
    fr: 'Accordez votre ukulélé, votre guitare acoustique, électrique ou basse',
    zh: '调好你的尤克里里、木吉他、电吉他或贝斯',
    de: 'Stimmen Sie Ihre Ukulele, akustik, elektrische oder Bassgitarre',
  },
  withMicrophoneOrByEar: {
    en: 'with Microphone or by Ear',
    es: 'con un Micrófono o de Oído',
    fr: "avec un Microphone ou à l'Oreille",
    zh: '用麦克风或耳朵',
    de: 'mit dem Mikrofon oder nach Gehör',
  },
  instrument: {
    en: 'Instrument',
    es: 'Instrumento',
    fr: 'Instrument',
    zh: '乐器',
    de: 'Instrument',
  },
  selectTuning: {
    en: 'Select tuning',
    es: 'Seleccione afinación',
    fr: "Accordage",
    zh: '选择调音',
    de: 'Stimmung',
  },
  microphone: {
    en: 'Microphone',
    es: 'Micrófono',
    fr: 'Microphone',
    zh: '麦克风',
    de: 'Mikrofon',
  },
  loopNote: {
    en: 'Loop Note',
    es: 'Repetir la nota',
    fr: 'Répéter la note',
    zh: '重复音符',
    de: 'Schleifen Notiz'
  },
  detectString: {
    en: 'Auto Detect String',
    es: 'Auto Detección',
    fr: 'Auto-corde détection',
    zh: '检测弦',
    de: 'Saite erkennen',
  },
  usingMicrophone: {
    en: 'Using Microphone',
    es: 'El uso de micrófono',
    fr: 'En utilisant le microphone',
    zh: '用麦克风',
    de: 'Verwendung des Mikrofons',
  },
  byEar: {
    en: 'By Ear',
    es: 'De oído',
    fr: "À l'oreille",
    zh: '用耳朵调音',
    de: 'Nach Gehör',
  },
  auto: {
    en: 'Auto',
    es: 'Auto',
    fr: 'Auto',
    zh: '带麦克风',
    de: 'Auto',
  },
  tuningByEar: {
    en: 'Tuning by Ear',
    es: 'La afinación de oído',
    fr: "En accordant à l'oreille",
    zh: '用耳朵调音',
    de: 'Stimmen nach Gehör',
  },
  comingSoon: {
    en: 'Coming soon',
    es: 'Próximamente',
    fr: 'Bientôt disponible',
    zh: '快来了',
    de: 'Demnächst'
  },
  tapTheNoteOnce: {
    en: (
      <>
        Tap the note <strong>once</strong>
      </>
    ),
    es: (
      <>
        Pulsa la nota <strong>una vez</strong>
      </>
    ),
    fr: (
      <>
        Appuyez <strong>une fois</strong> sur la note
      </>
    ),
    zh: (
      <>
        点击音调<strong>一次</strong>
      </>
    ),
    de: (
      <>
        Tippen Sie <strong>einmal</strong> auf die Note
      </>
    ),
  },
  listening: {
    en: 'Listening...',
    es: 'Escuchando...',
    fr: 'Écoute...',
    zh: '正在听。。。',
    de: 'Spiel eine Saite...',
  },
  tooLow: {
    en: 'Too low',
    es: 'Muy baja',
    fr: 'Trop bas',
    zh: '太低',
    de: 'Zu niedrig',
  },
  tooHigh: {
    en: 'Too high',
    es: 'Muy alta',
    fr: 'Trop élevé',
    zh: '太高',
    de: 'Zu hoch',
  },
  switchMicrophoneTooltip: {
    en: 'To tune with microphone, tap the switch.',
    es: 'Para afinar con micrófono, toca el interruptor.',
    fr: 'Pour accorder avec le microphone, appuyez sur le interrupteur.',
    zh: '更换模式为用麦克风调音',
    de: 'Tippen Sie auf den Schalter, um mit dem Mikrofon zu stimmen.',
  },

  /***** Text *****/

  contactMeAt: {
    en: 'Contact me at:',
    es: 'Contacta conmigo en:',
    fr: 'Contactez-moi à:',
    zh: '联系我:',
    de: 'Kontaktiere mich bei:',
  },
  'acoustic-guitar': {
    en: 'acoustic guitar',
    es: 'guitarra acústica',
    fr: 'guitare acoustique',
    zh: '木吉他',
    de: 'akustik Gitarre',
  },
  'electric-guitar': {
    en: 'electric guitar',
    es: 'bajo',
    fr: 'guitare électrique',
    zh: '电吉他',
    de: 'elektrische Gitarre',
  },
  'bass-guitar': {
    en: 'bass guitar',
    es: 'guitarra eléctrica',
    fr: 'guitare basse',
    zh: '贝斯吉他',
    de: 'bass Gitarre',
  },
  'guitar': {
    en: 'guitar',
    es: 'guitarra',
    fr: 'guitare',
    zh: '吉他',
    de: 'Gitarre',
  },
  howToUseHeader: {
    en: (
      <>
        HOW <strong>TO USE</strong>
      </>
    ),
    es: (
      <>
        ¿CÓMO <strong>USAR?</strong>
      </>
    ),
    fr: (
      <>
        COMMENT <strong>UTILISER</strong>
      </>
    ),
    zh: (
      <>
        怎么<strong>使用</strong>
      </>
    ),
    de: (
      <>
        ZUR <strong>ANWENDUNG</strong>
      </>
    ),
  },
  howToUseText: {
    en: ({ instrumentNames, t }) => (
      <>
        <p>
          You can tune your {t(instrumentNames[0])} with a microphone or by ear.
          Tuning the {t(instrumentNames[1])}{' '}
          <strong>automatically with a microphone</strong> is much easier,
          faster, and is our recommended option. However, tuning your instrument
          by ear will improve your musical ear in the long term, and can be a
          valuable skill to learn for the moments when you are not online.
          <br />
          <br />
          To tune your <strong>{t(instrumentNames[0])} automatically:</strong>
          <br />
        </p>
        <ol>
          <li>
            Press the Microphone switch to tune the {t(instrumentNames[1])}{' '}
            automatically. Make sure to allow the website to use your
            microphone.
          </li>
          <li>
            Get your instrument close to your microphone to let the tuner
            recognize the string you’re playing. If <em>Detect String</em> is
            on, the {t(instrumentNames[0])} tuner will try to automatically
            identify the string you’re tuning. Alternatively, press the string
            that you'd like to tune.
          </li>
          <li>
            Tuning your {t(instrumentNames[1])} has never been so easy. Enjoy
            your well-tuned instrument!
          </li>
        </ol>
        <br />
        <p>
          To tune your <strong>{t(instrumentNames[0])} by ear:</strong>
        </p>
        <ol>
          <li>
            Press the string you’re trying to tune. The selected string will
            play every 2 seconds so you can concentrate on your instrument.
          </li>
          <li>You’re improving your musical ear. Good job!</li>
        </ol>
        <br />
        <p>
          <strong>Pro tip:</strong> Make sure to bookmark this page to find it
          easily next time!
        </p>
      </>
    ),
    es: ({ instrumentNames, t }) => (
      <>
        <p>
          Puedes afinar tu {t(instrumentNames[0])} con un micrófono o de oído.
          Afinar la {t(instrumentNames[1])}{' '}
          <strong>automáticamente con un micrófono</strong> es más simple,
          rápido y es la opción que recomendamos. Sin embargo, afinar tu
          instrumento de oído mejorará tu oído musical en perspectiva, y puede
          ser una habilidad valiosa para los momentos cuando no estés online.
          <br />
          <br />
          Para afinar tu{' '}
          <strong> {t(instrumentNames[0])} automáticamente: </strong>
          <br />
        </p>
        <ol>
          <li>
            Pulsa el cambio de micrófono para afinar la {t(instrumentNames[1])}{' '}
            automáticamente. Asegúrete de permitir tu página web usar el
            micrófono.
          </li>
          <li>
            Coloca tu instrumento cerca del micrófono para que el afinador pueda
            reconocer la cadena que estás tocando. Si Detectar la Cadena está
            activado el el afinador de {t(instrumentNames[0])} tratará
            identificar automáticamente la cadena que afinas. O bien, pulsa la
            cadena que quisieras afinar.
          </li>
          <li>
            Afinar tu {t(instrumentNames[1])} nunca ha sido tan simple. Disfruta
            de tu instrumento bien afinado!
          </li>
        </ol>
        <br />
        <p>
          Para afinar tu <strong> {t(instrumentNames[0])} de oído: </strong>
        </p>
        <br />
        <ol>
          <li>
            Pulsa la cadena que intentas afinar. La cadena seleccionada tocará
            cada 2 segundos para que puedas concentrar en tu instrumento.
          </li>
          <li>Estás mejorando tu oído musical. ¡Buen trabajo!</li>
        </ol>
        <br />
        <p>
          <strong> Consejo profesional: </strong> asegúrate de marcar esta
          página para encontrarla fácilmente la próxima vez!
        </p>
      </>
    ),
    fr: ({ instrumentNames, t }) => (
      <>
        <p>
          Vous pouvez accorder votre {t(instrumentNames[0])} avec un microphone
          ou à l'oreille. L'accord automatique de la {t(instrumentNames[1])}{' '}
          <strong>avec un microphone</strong> est beaucoup plus facile et
          rapide, et c'est notre option recommandée. Cependant, l'accordage de
          votre instrument à l'oreille améliorera votre oreille musicale à long
          terme, et peut être une compétence précieuse à apprendre pour les
          moments où vous n'êtes pas en ligne.
          <br />
          <br />
          Pour accorder votre {t(instrumentNames[0])}{' '}
          <strong>automatiquement:</strong>
          <br />
        </p>
        <ol>
          <li>
            Appuyez sur le bouton Microphone pour accorder votre{' '}
            {t(instrumentNames[1])} automatiquement. Veillez à autoriser le site
            web à utiliser votre microphone.
          </li>
          <li>
            Rapprochez votre instrument de votre microphone pour que l'accordeur
            puisse reconnaître la corde que vous jouez. Si l'option{' '}
            <em>Auto-Corde</em> est activée, l'accordeur {t(instrumentNames[0])}{' '}
            essaiera d'identifier automatiquement la corde que vous accordez.
            Sinon, appuyez sur la corde que vous souhaitez accorder.
          </li>
          <li>
            Accorder votre {t(instrumentNames[1])} n'a jamais été aussi facile.
            Profitez de votre instrument bien accordé!
          </li>
        </ol>
        <br />
        <p>
          Pour accorder votre {t(instrumentNames[0])}{' '}
          <strong>à l'oreille:</strong>
        </p>
        <br />
        <ol>
          <li>
            Appuyez sur la corde que vous essayez d'accorder. La corde
            sélectionnée jouera toutes les 2 secondes pour que vous puissiez
            vous concentrer sur votre instrument.
          </li>
          <li>Vous améliorez ainsi votre oreille musicale. Bon travail!</li>
        </ol>
        <br />
        <p>
          <strong>Conseil du pro:</strong> Assurez-vous d'ajouter cette page à
          vos favoris pour la retrouver facilement la prochaine fois!
        </p>
      </>
    ),
    zh: ({ instrumentNames, t }) => (
      <>
        <p>
          你可以用麦克风或耳朵来调你的{t(instrumentNames[0])}
          。使用麦克风自动调谐{t(instrumentNames[0])}
          更容易、更快，是我们推荐的选择。然而，从长远来看，用耳朵调节乐器会提高你的音乐听觉，而且在你不在线的时候也是一项值得学习的技能。
          <br />
          <br />要<strong>自动调谐你的{t(instrumentNames[0])}</strong>：
          <br />
        </p>
        <ol>
          <li>
            按麦克风开关可自动调谐{t(instrumentNames[1])}
            。确保允许网站使用您的麦克风。
          </li>
          <li>
            把你的乐器拿到靠近你的麦克风，让调谐器识别你正在演奏的弦乐。如果检测弦乐处于启用状态，
            {t(instrumentNames[0])}
            调谐器将尝试自动识别正在调谐的弦乐。或者，按你要调谐的弦乐。
          </li>
          <li>{t(instrumentNames[1])}调音从未如此容易。享受你调好的乐器！</li>
        </ol>
        <br />
        <p>
          用<strong>耳朵调{t(instrumentNames[0])}:</strong>
        </p>
        <br />
        <ol>
          <li>
            按你要调的琴弦。选定的琴弦每2秒演奏一次，这样你就可以集中精力在乐器上了。
          </li>
          <li>你在提高你的音乐听觉。干得好！</li>
        </ol>
        <br />
        <p>
          <strong>专业提示：</strong>请务必将此页添加书签，以便下次轻松找到它！
        </p>
      </>
    ),
    de: ({ instrumentNames, t }) => (
      <>
        <p>
          Sie können Ihre {t(instrumentNames[0])} mit einem Mikrofon oder nach
          Gehör stimmen. Das automatische Stimmen der {t(instrumentNames[1])}{' '}
          <strong>mit einem Mikrofon</strong> ist viel einfacher, schneller und
          wird von uns empfohlen. Wenn Sie Ihr Instrument jedoch nach Gehör
          stimmen, wird sich Ihr musikalisches Gehör langfristig verbessern, und
          es kann eine wertvolle Fähigkeit sein, die Sie für die Momente lernen
          können, in denen Sie nicht online sind.
          <br />
          <br />
          So stimmen Sie Ihre {t(instrumentNames[0])}{' '}
          <strong>automatisch:</strong>
          <br />
        </p>
        <ol>
          <li>
            Drücken Sie den Mikrophonschalter, um die {t(instrumentNames[1])}{' '}
            automatisch zu stimmen. Stellen Sie sicher, dass die Website die
            Verwendung Ihres Mikrofons zulässt.
          </li>
          <li>
            Bringen Sie Ihr Instrument nah an Ihr Mikrofon, damit das Stimmgerät
            die von Ihnen gespielte Saite erkennen kann. Wenn "Saite erkennen"
            eingeschaltet ist, wird das Stimmgerät {t(instrumentNames[0])}{' '}
            versuchen, die Saite, die Sie gerade stimmen, automatisch zu
            erkennen. Alternativ können Sie auch die Saite drücken, die Sie
            stimmen möchten.
          </li>
          <li>
            Das Stimmen Ihrer {t(instrumentNames[1])} war noch nie so einfach.
            Genießen Sie Ihr gut gestimmtes Instrument!
          </li>
        </ol>
        <br />
        <p>
          So stimmen Sie Ihre {t(instrumentNames[0])}{' '}
          <strong>nach Gehör:</strong>
        </p>
        <br />
        <ol>
          <li>
            Drücken Sie die Saite, die Sie zu stimmen versuchen. Die gewählte
            Saite wird alle 2 Sekunden gespielt, damit Sie sich auf Ihr
            Instrument konzentrieren können.
          </li>
          <li>Sie verbessern Ihr musikalisches Gehör. Gute Arbeit!</li>
        </ol>
        <br />
        <p>
          <strong>Profi-Tipp:</strong> Setzen Sie ein Lesezeichen auf diese
          Seite, um sie beim nächsten Mal leicht wiederzufinden!
        </p>
      </>
    ),
  },
}

export default strings
