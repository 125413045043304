export const defaultLocale = 'en'

export const languageNames = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  zh: '中文',
}

export const locales = Object.keys(languageNames)
export const nonEnglishLocales = locales.filter(locale => locale !== 'en')

export function isLocale(tested) {
  return locales.some(locale => locale === tested)
}